import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-4b7794ee"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "logo"
};
var _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _a;
  var _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["logo-container", (_a = {}, _a['logo-container-' + $setup.theme.layout] = true, _a)])
  }, [_createVNode(_component_router_link, {
    to: "/"
  }, {
    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_1, [_createCommentVNode(" 使用自定义svg示例 "), $setup.logo ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        src: $setup.logo
      }, null, 8 /* PROPS */, _hoisted_2)) : _createCommentVNode("v-if", true), _createCommentVNode(" <vab-icon v-if=\"logo\" :icon=\"logo\" is-custom-svg /> ")]), _createElementVNode("span", {
        class: _normalizeClass(["title", {
          'hidden-xs-only': $setup.theme.layout === 'horizontal'
        }])
      }, _toDisplayString($setup.title), 3 /* TEXT, CLASS */)];
    }),

    _: 1 /* STABLE */
  })], 2 /* CLASS */);
}