import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-587b15c8"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "avatar-dropdown"
};
var _hoisted_2 = {
  class: "user-name"
};
var _hoisted_3 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_avatar = _resolveComponent("el-avatar");
  var _component_vab_icon = _resolveComponent("vab-icon");
  var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  var _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createBlock(_component_el_dropdown, {
    onCommand: $setup.handleCommand,
    onVisibleChange: $setup.handleVisibleChange
  }, {
    dropdown: _withCtx(function () {
      return [_createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(function () {
          return [_createCommentVNode(" <el-dropdown-item command=\"personalCenter\">\n          <vab-icon icon=\"user-line\" />\n          <span>{{ translate('个人中心') }}</span>\n        </el-dropdown-item> "), _createVNode(_component_el_dropdown_item, {
            command: "logout"
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_vab_icon, {
                icon: "logout-circle-r-line"
              }), _createElementVNode("span", null, _toDisplayString($setup.translate('退出登录')), 1 /* TEXT */)];
            }),

            _: 1 /* STABLE */
          })];
        }),

        _: 1 /* STABLE */
      })];
    }),

    default: _withCtx(function () {
      return [_createElementVNode("span", _hoisted_1, [_createVNode(_component_el_avatar, {
        class: "user-avatar",
        src: $setup.avatar
      }, null, 8 /* PROPS */, ["src"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("span", {
        class: "hidden-xs-only",
        title: $setup.username
      }, _toDisplayString($setup.username), 9 /* TEXT, PROPS */, _hoisted_3), _createVNode(_component_vab_icon, {
        class: _normalizeClass(["vab-dropdown", {
          'vab-dropdown-active': $setup.active
        }]),
        icon: "arrow-down-s-line"
      }, null, 8 /* PROPS */, ["class"])])])];
    }),
    _: 1 /* STABLE */
  });
}