import { __awaiter, __generator } from "tslib";
/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { useAclStore } from './acl';
import { useTabsStore } from './tabs';
import { useRoutesStore } from './routes';
import { useSettingsStore } from './settings';
import { getUserInfo, login } from '@/api/user';
import { getToken, removeToken, setToken } from '@/utils/token';
import { resetRouter } from '@/router';
import { isArray, isString } from '@/utils/validate';
import { tokenName } from '@/config';
import { gp } from '@gp';
var avatar = require("@/assets/base_images/mother.png");
var username = '管理员';
export var useUserStore = defineStore('user', {
  state: function () {
    return {
      token: getToken(),
      username: username,
      avatar: avatar
    };
  },
  getters: {
    getToken: function (state) {
      return state.token;
    },
    getUsername: function (state) {
      return state.username;
    },
    getAvatar: function (state) {
      return state.avatar;
    }
  },
  actions: {
    /**
     * @description 设置token
     * @param {*} token
     */
    setToken: function (token) {
      this.token = token;
      setToken(token);
    },
    /**
     * @description 设置用户名
     * @param {*} username
     */
    setUsername: function (username) {
      this.username = username;
    },
    /**
     * @description 设置头像
     * @param {*} avatar
     */
    setAvatar: function (avatar) {
      this.avatar = avatar;
    },
    /**
     * @description 登录拦截放行时，设置虚拟角色
     */
    setVirtualRoles: function () {
      var aclStore = useAclStore();
      aclStore.setFull(true);
      this.setUsername('admin(未开启登录拦截)');
      this.setAvatar(avatar);
    },
    /**
     * @description 设置token并发送提醒
     * @param {string} token 更新令牌
     * @param {string} tokenName 令牌名称
     */
    afterLogin: function (token, tokenName) {
      var settingsStore = useSettingsStore();
      if (token) {
        this.setToken(token);
        var hour = new Date().getHours();
        var thisTime = hour < 8 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 18 ? '下午好' : '晚上好';
        gp.$baseNotify("\u6B22\u8FCE\u767B\u5F55".concat(settingsStore.title), "".concat(thisTime, "\uFF01"));
      } else {
        var err = "\u767B\u5F55\u63A5\u53E3\u5F02\u5E38\uFF0C\u672A\u6B63\u786E\u8FD4\u56DE".concat(tokenName, "...");
        gp.$baseMessage(err, 'error', 'vab-hey-message-error');
        throw err;
      }
    },
    /**
     * @description 登录
     * @param {*} userInfo
     */
    login: function (userInfo) {
      return __awaiter(this, void 0, void 0, function () {
        var _a, _b, _c, access_token, name, mobile;
        return __generator(this, function (_d) {
          switch (_d.label) {
            case 0:
              return [4 /*yield*/, login(userInfo)];
            case 1:
              _a = _d.sent(), _b = _a.data, _c = tokenName, access_token = _b[_c], name = _b.name, mobile = _b.mobile;
              this.afterLogin(access_token, tokenName);
              this.setUsername(name || mobile);
              return [2 /*return*/];
          }
        });
      });
    },

    /**
     * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
     * @returns
     */
    getUserInfo: function () {
      return __awaiter(this, void 0, void 0, function () {
        var _a, username, avatar, roles, permissions, err, aclStore;
        return __generator(this, function (_b) {
          switch (_b.label) {
            case 0:
              return [4 /*yield*/, getUserInfo()
              /**
               * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Permissions
               * username {String}
               * avatar {String}
               * roles {List}
               * ability {List}
               */];

            case 1:
              _a = _b.sent().data, username = _a.username, avatar = _a.avatar, roles = _a.roles, permissions = _a.permissions;
              /**
               * 检验返回数据是否正常，无对应参数，将使用默认用户名,头像,Roles和Permissions
               * username {String}
               * avatar {String}
               * roles {List}
               * ability {List}
               */
              if (username && !isString(username) || avatar && !isString(avatar) || roles && !isArray(roles) || permissions && !isArray(permissions)) {
                err = 'getUserInfo核心接口异常，请检查返回JSON格式是否正确';
                gp.$baseMessage(err, 'error', 'vab-hey-message-error');
                throw err;
              } else {
                aclStore = useAclStore();
                // 如不使用username用户名,可删除以下代码
                if (username) this.setUsername(username);
                // 如不使用avatar头像,可删除以下代码
                if (avatar) this.setAvatar(avatar);
                // 如不使用roles权限控制,可删除以下代码
                if (roles) aclStore.setRole(roles);
                // 如不使用permissions权限控制,可删除以下代码
                if (permissions) aclStore.setPermission(permissions);
              }
              return [2 /*return*/];
          }
        });
      });
    },

    /**
     * @description 退出登录
     */
    logout: function () {
      return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              //await logout()
              return [4 /*yield*/, this.resetAll()
              // 解决横向布局退出登录显示不全的bug
              ];

            case 1:
              //await logout()
              _a.sent();
              // 解决横向布局退出登录显示不全的bug
              location.reload();
              return [2 /*return*/];
          }
        });
      });
    },

    /**
     * @description 重置token、roles、permission、router、tabsBar等
     */
    resetAll: function () {
      return __awaiter(this, void 0, void 0, function () {
        var aclStore, routesStore, tabsStore;
        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              this.setToken('');
              this.setUsername(username);
              this.setAvatar(avatar);
              aclStore = useAclStore();
              routesStore = useRoutesStore();
              tabsStore = useTabsStore();
              aclStore.setPermission([]);
              aclStore.setFull(false);
              aclStore.setRole([]);
              tabsStore.delAllVisitedRoutes();
              routesStore.clearRoutes();
              return [4 /*yield*/, resetRouter()];
            case 1:
              _a.sent();
              removeToken();
              return [2 /*return*/];
          }
        });
      });
    }
  }
});