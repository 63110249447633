import request from '@/utils/request'

export async function login(data: any) {
  return request({
    url: '/v1/account/login',
    method: 'put',
    data,
  })
}

export function getUserInfo() {
  return request({
    url: '/userInfo',
    method: 'get',
  })
}

export function register(data: any) {
  return request({
    url: '/customer/account/register',
    method: 'post',
    data,
  })
}

export function operateIntegral(data: any) {
  return request({
    url: '/v3/user/operateIntegral',
    method: 'post',
    data,
  })
}
