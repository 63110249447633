import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-0a2083c2"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = ["href"];
var _hoisted_3 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");
  var _component_el_badge = _resolveComponent("el-badge");
  var _component_el_tag = _resolveComponent("el-tag");
  var _component_el_table_column = _resolveComponent("el-table-column");
  var _component_el_button = _resolveComponent("el-button");
  var _component_el_popover = _resolveComponent("el-popover");
  var _component_el_table = _resolveComponent("el-table");
  var _component_el_dialog = _resolveComponent("el-dialog");
  return $setup.errorLogs.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_badge, {
    type: "danger",
    value: $setup.errorLogs.length,
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return $setup.state.dialogVisible = true;
    })
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_vab_icon, {
        icon: "bug-line"
      })];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["value"]), _createVNode(_component_el_dialog, {
    modelValue: $setup.state.dialogVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return $setup.state.dialogVisible = $event;
    }),
    "append-to-body": "",
    title: "admin-plus 异常捕获(温馨提示：错误必须解决)",
    width: "70%"
  }, {
    footer: _withCtx(function () {
      return [_createVNode(_component_el_button, {
        onClick: _cache[1] || (_cache[1] = function ($event) {
          return $setup.state.dialogVisible = false;
        })
      }, {
        default: _withCtx(function () {
          return [_createTextVNode("取 消")];
        }),
        _: 1 /* STABLE */
      }), _createVNode(_component_el_button, {
        type: "danger",
        onClick: $setup.clearAll
      }, {
        default: _withCtx(function () {
          return [_createTextVNode("暂不显示")];
        }),
        _: 1 /* STABLE */
      })];
    }),

    default: _withCtx(function () {
      return [_createVNode(_component_el_table, {
        border: "",
        data: $setup.errorLogs
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_table_column, {
            label: "报错路由"
          }, {
            default: _withCtx(function (_a) {
              var row = _a.row;
              return [_createElementVNode("a", {
                href: row.url,
                target: "_blank"
              }, [_createVNode(_component_el_tag, {
                type: "success"
              }, {
                default: _withCtx(function () {
                  return [_createTextVNode(_toDisplayString(row.url), 1 /* TEXT */)];
                }),

                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)], 8 /* PROPS */, _hoisted_2)];
            }),
            _: 1 /* STABLE */
          }), _createVNode(_component_el_table_column, {
            label: "错误信息"
          }, {
            default: _withCtx(function (_a) {
              var row = _a.row;
              return [_createVNode(_component_el_tag, {
                type: "danger"
              }, {
                default: _withCtx(function () {
                  return [_createTextVNode(_toDisplayString(row.err.message), 1 /* TEXT */)];
                }),

                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)];
            }),

            _: 1 /* STABLE */
          }), _createVNode(_component_el_table_column, {
            label: "错误详情",
            width: "120"
          }, {
            default: _withCtx(function (_a) {
              var row = _a.row;
              return [_createVNode(_component_el_popover, {
                placement: "top-start",
                trigger: "hover"
              }, {
                reference: _withCtx(function () {
                  return [_createVNode(_component_el_button, null, {
                    default: _withCtx(function () {
                      return [_createTextVNode("查看")];
                    }),
                    _: 1 /* STABLE */
                  })];
                }),

                default: _withCtx(function () {
                  return [_createTextVNode(_toDisplayString(row.err.stack) + " ", 1 /* TEXT */)];
                }),

                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)];
            }),

            _: 1 /* STABLE */
          }), _createVNode(_component_el_table_column, {
            label: "操作",
            width: "380"
          }, {
            default: _withCtx(function (_a) {
              var row = _a.row;
              return [(_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.searchList, function (item, index) {
                return _createElementVNode("a", {
                  key: index,
                  href: item.url + row.err.message,
                  target: "_blank"
                }, [_createVNode(_component_el_button, null, {
                  default: _withCtx(function () {
                    return [_createVNode(_component_vab_icon, {
                      icon: item.icon
                    }, null, 8 /* PROPS */, ["icon"]), _createTextVNode(" " + _toDisplayString(item.title), 1 /* TEXT */)];
                  }),

                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)], 8 /* PROPS */, _hoisted_3);
              }), 64 /* STABLE_FRAGMENT */))];
            }),

            _: 1 /* STABLE */
          })];
        }),

        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data"])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"])])) : _createCommentVNode("v-if", true);
}