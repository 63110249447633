import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");
  return $setup.theme.showTheme ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_vab_icon, {
    icon: "brush-2-line",
    onClick: $setup.handleOpenTheme
  })])) : _createCommentVNode("v-if", true);
}