import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) {
  return _pushScopeId("data-v-21cef8eb"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  class: "vab-theme-setting"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_vab_icon = _resolveComponent("vab-icon");
  return $setup.theme.showThemeSetting ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [_createElementVNode("li", {
    onClick: $setup.handleOpenTheme
  }, [_createElementVNode("a", null, [_createVNode(_component_vab_icon, {
    icon: "brush-2-line"
  }), _createElementVNode("p", null, _toDisplayString($setup.translate('主题配置')), 1 /* TEXT */)])]), _createElementVNode("li", {
    onClick: $setup.randomTheme
  }, [_createElementVNode("a", null, [_createVNode(_component_vab_icon, {
    icon: "apps-line"
  }), _createElementVNode("p", null, _toDisplayString($setup.translate('随机换肤')), 1 /* TEXT */)])]), _createElementVNode("li", {
    onClick: $setup.buy
  }, [_createElementVNode("a", null, [_createVNode(_component_vab_icon, {
    icon: "shopping-cart-2-line"
  }), _createElementVNode("p", null, _toDisplayString($setup.translate('购买源码')), 1 /* TEXT */)])]), _createElementVNode("li", {
    onClick: $setup.getCode
  }, [_createElementVNode("a", null, [_createVNode(_component_vab_icon, {
    icon: "file-copy-line"
  }), _createElementVNode("p", null, _toDisplayString($setup.translate('拷贝源码')), 1 /* TEXT */)])]), _createElementVNode("li", {
    onClick: $setup.removeLocalStorage
  }, [_createElementVNode("a", null, [_createVNode(_component_vab_icon, {
    icon: "delete-bin-4-line"
  }), _createElementVNode("p", null, _toDisplayString($setup.translate('清理缓存')), 1 /* TEXT */)])])])) : _createCommentVNode("v-if", true);
}